import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "scrollable", "left", "right" ]

  connect() {
    setTimeout(() => {
      this.updateVisibility()
    }, 200);
  }
  
  right() {
    this.scrollableTarget.scrollLeft += 500
  }

  left() {
    this.scrollableTarget.scrollLeft -= 500
  }
  
  
  updateVisibility() {
    let scrollable = this.scrollableTarget
    
    let leftEdge = 0
    let rightEdge = scrollable.scrollWidth - scrollable.clientWidth

    let scrollingPossible = scrollable.scrollWidth > scrollable.offsetWidth
    
    this.setVisibility( this.leftTarget, scrollingPossible && scrollable.scrollLeft != leftEdge )
    this.setVisibility( this.rightTarget, scrollingPossible && scrollable.scrollLeft != rightEdge )
  }
  
  setVisibility(element, visible) {
    if (visible)
      element.classList.remove("hidden")
    else
      element.classList.add("hidden")
  }
}