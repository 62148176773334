function scrollToTop (duration) {
  // cancel if already on top
  if (document.scrollingElement.scrollTop === 0) return;

  const cosParameter = document.scrollingElement.scrollTop / 2;
  let scrollCount = 0, oldTimestamp = null;

  function step (newTimestamp) {
      if (oldTimestamp !== null) {
          // if duration is 0 scrollCount will be Infinity
          scrollCount += Math.PI * (newTimestamp - oldTimestamp) / duration;
          if (scrollCount >= Math.PI) return document.scrollingElement.scrollTop = 0;
          document.scrollingElement.scrollTop = cosParameter + cosParameter * Math.cos(scrollCount);
      }
      oldTimestamp = newTimestamp;
      window.requestAnimationFrame(step);
  }
  window.requestAnimationFrame(step);
}




document.addEventListener("turbolinks:load", function () {

  var scrollToTopButton = document.querySelector("[data-javascript='scroll-to-top']")

  if (!scrollToTopButton) return
    
  function updateVisibility() {
    if (window.scrollY > 600) {
      scrollToTopButton.classList.remove("opacity-0")
    } else {
      scrollToTopButton.classList.add("opacity-0")
    }
  }
  
  updateVisibility()  

  window.addEventListener("scroll", updateVisibility)

  scrollToTopButton.addEventListener("click", function() {
    scrollToTop(400)
  })
  
})