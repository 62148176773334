import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  submit() {
    Rails.fire(this.element, "submit")
    
    // "loading indicator" for sidebar
    document.querySelector("h1").textContent = "Loading..."
    document.getElementById("grid").style.opacity = 0.5
  }
}