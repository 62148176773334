import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "element" ]
  
  connect() {
    this.hideAll()
  }
  
  showMatching(event) {
    const triggerId = event.target.dataset.hidden
    
    this.hideAll()
    
    this.elementTargets
      .find(element => element.dataset.hidden == triggerId)
      .classList.toggle("hidden")
  }
  
  hideAll() {
    this.elementTargets.forEach(element => 
      element.classList.add("hidden"))
  }
}