import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["selectable", "upcContainer", "upcInput", "skuContainer", "skuInput"]
  
  connect() {
    this.update()
  }
  
  update() {
    const selectedRadioButton = this.selectableTargets.find(radio => radio.checked)
    const selectedIdentifier = selectedRadioButton.value

    this.disable("upc")
    this.disable("sku")

    this.enable(selectedIdentifier)
  }

  disable(identifier) {
    this[identifier + "ContainerTarget"].classList.add("hidden")
  }

  enable(identifier) {
    this[identifier + "ContainerTarget"].classList.remove("hidden")
  }
}