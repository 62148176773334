const turbolinksPersistScroll = () => {
  const persistScrollDataAttribute = 'turbolinks-persist-scroll'
  let scrollPosition = null
  let enabled = false

  let filterScrollPosition = null

  document.addEventListener('turbolinks:before-visit', (event) => {
    if (enabled) {
      scrollPosition = window.scrollY
      filterScrollPosition = document.querySelector("[data-scrollable]").scrollLeft
    } else {
      scrollPosition = null
    }
    enabled = false
  })

  document.addEventListener('turbolinks:load', (event) => {
    const elements = document.querySelectorAll(`[data-${persistScrollDataAttribute}="true"]`)
    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener('click', () => {
        enabled = true
      })
    }

    if (scrollPosition || filterScrollPosition) {
      window.scrollTo(0, scrollPosition)
      
      document.querySelector("[data-scrollable]").style.scrollBehavior = "initial"
      document.querySelector("[data-scrollable]").scrollLeft = filterScrollPosition
      document.querySelector("[data-scrollable]").style.scrollBehavior = "smooth"
    }
  })
}

turbolinksPersistScroll()