import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "container", "backdrop" ]
  
  show() {
    this.containerTarget.style.left = "0px"
    
    this.backdropTarget.classList.remove("invisible")
    this.backdropTarget.style.opacity = 1
  }
  
  hide() {
    this.containerTarget.style.left = "-500px"
    
    this.backdropTarget.style.opacity = 0
    setTimeout(() => {
      this.backdropTarget.classList.add("invisible")
    }, 200)
  }
}