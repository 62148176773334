import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "menu" ]
  
  connect() {
    this.menuTarget.style.transition = "max-height 600ms"
  }

  toggle() {
    if (this.menuTarget.style.maxHeight == "0px")
      this.menuTarget.style.maxHeight = "500px"
    else
      this.menuTarget.style.maxHeight = "0px"
  }
}