import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "button", "replacement", "input" ]

  showButton() {
    this.buttonTarget.classList.remove("hidden")
    this.replacementTarget.classList.add("hidden")
  }
  
  hideButton() {
    if (this.inputTarget.value.length > 0) return
    
    this.buttonTarget.classList.add("hidden")
    this.replacementTarget.classList.remove("hidden")
  }
}